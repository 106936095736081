import { Modal } from 'antd';
import { useState } from 'react';
import { DEFAULT_IMAGE_IN_CATALOGUE } from 'shared/utils/constant';

export function ImageViewer({ imageUrls, alt, height }: { imageUrls: string[]; alt?: string; height?: number | string }) {
	const [showModal, setShowModal] = useState(false);

	const closeModal = () => {
		setShowModal(false);
	};

	const imgUrl = imageUrls.length && imageUrls[0] !== '' ? imageUrls[0] : DEFAULT_IMAGE_IN_CATALOGUE;
	const imgAltTxt = alt || (imageUrls.length ? 'image' : 'default');

	const openModal = () => {
		if (imgUrl) {
			setShowModal(true);
		}
	};

	return (
		<>
			<img
				src={imgUrl}
				style={{ height: height || 'auto', maxWidth: '100%', cursor: 'pointer' }}
				alt={imgAltTxt}
				onClick={openModal}
			/>
			<Modal visible={showModal} title="View Image" onOk={closeModal} onCancel={closeModal} width={'60vw'}>
				<div style={{ textAlign: 'center', userSelect: 'none' }}>
					<img src={imgUrl} style={{ maxHeight: '70vh', maxWidth: '100%' }} alt={imgAltTxt} />
				</div>
			</Modal>
		</>
	);
}
