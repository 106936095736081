import { Button, Tag, Tooltip } from 'antd';
import styles from './index.module.scss';
import { getWSINMapFormat } from '../../../../shared/components/Catalog/constants';
import { ImageViewer } from '../../../../shared/components/ImageViewer/ImageViewer';

export const adminCatalogColumns = ({ clickCatalogueId }) => [
	{
		title: 'Images',
		dataIndex: 'imageUrls',
		width: 130,
		render: (_, { imageUrls }) => <ImageViewer imageUrls={imageUrls} height={50} />
	},
	{
		title: 'Serial No',
		dataIndex: 'productId',
		width: 130
	},
	{
		title: 'Item Name',
		dataIndex: 'productTitle',
		ellipsis: {
			showTitle: true
		},
		render: (v, catalogue) => (
			<div className={styles.skuDetail} style={{ overflowWrap: 'break-word !important' }}>
				<div className={styles.wrapper}>
					<Button
						type="link"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							clickCatalogueId(catalogue.id);
						}}
					>
						<Tooltip placement="topLeft" title={catalogue.productTitle}>
							<div className={styles.sub}>{catalogue.productTitle}</div>
						</Tooltip>
					</Button>
				</div>
			</div>
		)
	},
	{
		title: 'SKU',
		dataIndex: 'sku'
	},
	{
		title: 'WSIN Map',
		dataIndex: 'wsinMapping',
		render: (v, items) =>
			items.wsinMapping ? (
				getWSINMapFormat(items.wsinMapping)
					.split('+')
					.map((e) => <p>{e}</p>)
			) : (
				<Tag color="red">Not Generated</Tag>
			)
	},
	{
		title: 'Shop Type',
		dataIndex: 'shopType'
	},
	{
		title: 'Price',
		dataIndex: 'price'
	},
	{
		title: 'LxBxH (cm)',
		dataIndex: 'mappedDimensions'
	},
	{
		title: 'Weight (gm)',
		dataIndex: 'weight'
	},
	{
		title: 'HSN',
		dataIndex: 'hsnCode'
	},
	{
		title: 'Base Price',
		dataIndex: 'basePrice'
	},
	{
		title: 'Margin (%)',
		dataIndex: 'margin'
	},
	{
		title: 'D2R Category',
		dataIndex: 'd2rCategory'
	},
	{
		title: 'Category',
		dataIndex: 'category'
	},
	{
		title: 'Active',
		dataIndex: 'active',
		render: (v, items) => (items.active === 1 ? <Tag color="blue">Active</Tag> : <Tag color="red">In Active</Tag>)
	}
];
