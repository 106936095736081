import { Badge, Button, Col, Popover, Row, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import export_svg from '../../../../shared/svgs/export_svg';
import { commonTopFilterRow } from '../../../../utils/filters';
import { ICommonFilter, IPageConfiguration } from '../../commonTypes';
import { companyType } from '../D2r';

export const searchOptions = [
	{ value: 'invoiceId', label: 'Invoice Id' },
	{ value: 'creditNoteId', label: 'Credit Note Id' },
	{ value: 'retailer', label: 'Retailer Name' }
];

export const defaultFilter: ICommonFilter = {
	start: dayjs().subtract(30, 'day'),
	end: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const defaultPageConfig: IPageConfiguration = {
	current: 1,
	pageSize: 10,
	sortField: 'createdAt',
	sortOrder: 'DESC'
};

export interface IFilter extends ICommonFilter {
	status?: number;
	company?: companyType;
}

export function topFilterUtil({
	filter,
	setFilter,
	setPageConfigDefault,
	selectedRowKeys,
	onCSVDownload,
	onDownloadCreditVoucherXml,
	onDownloadCreditVoucherExcel,
	isVoucherXmlLoading,
	isVoucherExcelLoading
}: any): any {
	const setDate = (startDate: string, endDate: string) => {
		setPageConfigDefault();
		setFilter((prevFilter: IFilter) => ({ ...prevFilter, start: startDate, end: endDate }));
	};
	const topFilterRow = commonTopFilterRow(filter.start, filter.end);

	return {
		row1: [
			{
				...topFilterRow,
				onChange: (dateStrings: [string, string]) => setDate(...dateStrings)
			}
		],
		row1children: [
			<div key={1}>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
					selectStyle={{ minWidth: '150px' }}
				>
					<span>Search By:</span>
				</DebouncedCompactSearch>
			</div>,
			<div key={1}>
				{selectedRowKeys.length !== 0 && (
					<Badge size="default" count={`${selectedRowKeys.length} selected`} style={{ backgroundColor: '#6c757d' }} />
				)}
			</div>
		],
		row2: [
			<Row key={2}>
				<Col>
					<Tooltip placement="left" title={'Export details for all orders'}>
						<Button style={{ marginTop: '1.5rem', marginRight: '0.5rem' }} onClick={onCSVDownload}>
							<span>{export_svg}</span>
							<span style={{ marginLeft: '.4rem', fontSize: '0.85rem' }}>Export</span>
						</Button>
					</Tooltip>
				</Col>
				<Col>
					<Popover
						style={{ maxWidth: 100 }}
						content={
							<Space direction="vertical" className={'ml-0'} key={'1'}>
								<label style={{ marginBottom: '5px', maxWidth: '350px' }}>
									{`Tally Voucher will be downloaded for ${selectedRowKeys.length} selected order${
										selectedRowKeys.length > 1 ? 's' : ''
									}.`}
								</label>

								<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
									<Button
										type="primary"
										onClick={onDownloadCreditVoucherXml}
										style={{ marginTop: '30px' }}
										loading={isVoucherXmlLoading}
									>
										<b>XML</b>
									</Button>
									<Button
										type="primary"
										onClick={onDownloadCreditVoucherExcel}
										style={{ marginTop: '30px' }}
										loading={isVoucherExcelLoading}
									>
										<b>Excel</b>
									</Button>
								</div>
							</Space>
						}
						title="Select Format"
						trigger="click"
					>
						<Button style={{ marginTop: '1.5rem' }}>
							<span>{<DownloadOutlined />}</span>
							<span style={{ marginLeft: '.4rem', fontSize: '0.85rem' }}>Voucher</span>
						</Button>
					</Popover>
				</Col>
			</Row>
		]
	};
}
