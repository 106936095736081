import { DatePicker, Form, Select, Space } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { DEFAULT_INVENTORY_TRAIL_FILTER } from '../InventoryTrailAndDetails';
import { SELECT_AND_FILTER_INVENTORY } from './utils';
import styles from './index.module.scss';

interface IInventoryFilter {
	filter: typeof DEFAULT_INVENTORY_TRAIL_FILTER;
	setFilter: React.Dispatch<React.SetStateAction<typeof DEFAULT_INVENTORY_TRAIL_FILTER>>;
}

export const InventoryFilter = (props: IInventoryFilter) => {
	const { filter, setFilter } = props;

	const onDatePickerChange = useCallback(
		(_dates: any, dateStrings: [string, string]) => {
			setFilter({
				...filter,
				trailsBetweenTime: {
					start: dateStrings[0],
					end: dateStrings[1]
				}
			});
		},
		[filter, setFilter]
	);

	const handleSelectChange = (data: string) => {
		setFilter({
			...filter,
			inventoryTrailFilters: data
		});
	};

	const handleClear = () =>
		setFilter({
			...filter,
			inventoryTrailFilters: ''
		});

	const datePickerProps = useMemo(
		() => ({
			label: 'FILTER BY DATE',
			onChange: (dates: any, dateStrings: [string, string]) => onDatePickerChange(dates, dateStrings),
			type: 'range',
			allowClear: false,
			value: [dayjs(filter.trailsBetweenTime.start), dayjs(filter.trailsBetweenTime.end)],
			defaultValue: [dayjs(filter.trailsBetweenTime.start), dayjs(filter.trailsBetweenTime.end)],
			ranges: {
				Today: [dayjs().startOf('date'), dayjs()],
				'Last 1 Week': [dayjs().subtract(1, 'w'), dayjs()],
				'Last 1 Month': [dayjs().subtract(1, 'M'), dayjs()],
				'Last 3 Months': [dayjs().subtract(3, 'M'), dayjs()],
				'Last 1 Year': [dayjs().subtract(1, 'y'), dayjs()]
			}
		}),
		[filter.trailsBetweenTime.start, filter.trailsBetweenTime.end, onDatePickerChange]
	);
	return (
		<>
			<div className={styles.filterParentDiv}>
				<div className={styles.filterDiv}>
					<Form.Item name="weight" label="Filter Trail by Date" rules={[{ required: false, message: 'Weight is required!' }]}>
						{/* @ts-ignore //! Don't remove it. */}
						<DatePicker.RangePicker {...datePickerProps} />
					</Form.Item>
					<Form.Item name="weight" label="Select" rules={[{ required: false, message: 'Weight is required!' }]}>
						<Space direction="vertical" style={{ width: 200, display: 'flex' }}>
							<Select
								placeholder="Select Trail Type"
								value={filter.inventoryTrailFilters || undefined}
								onChange={handleSelectChange}
								allowClear
								onClear={handleClear}
							>
								{SELECT_AND_FILTER_INVENTORY.map((e) => (
									<Select.Option value={e.value}>{e.name}</Select.Option>
								))}
							</Select>
						</Space>
					</Form.Item>
				</div>
			</div>
		</>
	);
};
