import { cleanObject } from 'helper';
import { apiOms, apiWms } from './index';
import errorHandler from '../utils/errorHandler';
import { envs } from '../utils/env';
const { omsBaseUrl } = envs;

function getCompanyInventory({ current = 1, pageSize = 10, sortField = 'id', sortOrder = 'ASC' }, filter) {
	filter.searchFilter = {
		searchOptionType: filter.searchOptionType,
		searchValue: filter.searchValue
	};
	delete filter.searchOptionType;
	delete filter.searchValue;
	return apiWms.post('/inventory/fetch', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
}

function getInventoryDetail(inventoryId) {
	return apiWms.post(`/inventory/trail/${inventoryId}`);
}

function updateInventoryStock({ quantity, remark }, inventoryId) {
	return apiWms.post(`/warehouse/add-items`, {
		quantity,
		inventoryId,
		reason: remark
	});
}

function getWarehouseInventory({ current = 1, pageSize = 10, sortField = 'id', sortOrder = 'ASC' }, filter, companyIds) {
	return apiWms.post('/warehouse/inventory', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		},
		companyIds
	});
}

function addInventory({ date, inboundAccepted, inboundRejected, remark, inventoryId }) {
	return apiWms.post(`/inventory/add/${inventoryId}`, {
		date,
		inboundAccepted,
		inboundRejected,
		remark
	});
}

function adjustInventory({ date, quantity, remark, inventoryId, inventoryAdjustmentType }) {
	return apiWms.post(`/inventory/adjust/${inventoryId}`, {
		date,
		quantity,
		remark,
		inventoryAdjustmentType
	});
}

function getCreditNotes(pageConfiguration, filters, csvFlag = 0) {
	try {
		const { start, end, searchOptionType, searchValue } = filters;
		const { current, pageSize } = pageConfiguration;

		const searchParams = new URLSearchParams();
		if (searchValue && searchOptionType) {
			searchParams.append('searchOptionType', searchOptionType);
			searchParams.append('searchValue', searchValue.trim());
		}

		// apply filters
		const singleFieldMap = {
			record: pageSize,
			page: current,
			csvFlag
		};

		Object.entries(singleFieldMap).forEach(([fieldName, fieldValue]) => {
			searchParams.append(fieldName, String(fieldValue));
		});

		// attach other filters
		if (start && end) {
			searchParams.append('startDate', String(start));
			searchParams.append('endDate', String(end));
		}

		const apiUrl = `/credit-note?${searchParams.toString()}`;

		if (csvFlag) {
			const url = new URL(`${omsBaseUrl}${apiUrl}`);
			window.open(url);
		}

		return apiOms.get(apiUrl);
	} catch (error) {
		errorHandler(error);
	}
}

export {
	getCompanyInventory,
	getInventoryDetail,
	updateInventoryStock,
	getWarehouseInventory,
	addInventory,
	adjustInventory,
	getCreditNotes
};
