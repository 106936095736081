import { Tooltip } from 'antd';
import React from 'react';

interface HighlightedTextProps {
	text: string;
	highlight?: string;
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({ text, highlight }) => {
	if (!highlight?.trim()) return <span>{text}</span>;

	const parts = text.split(new RegExp(`(${highlight.replace(/\s+/g, '\\s+')})`, 'gi'));

	return (
		<Tooltip title={text} color="blue">
			<span>
				{parts.map((part, i) =>
					part.toLowerCase() === highlight.toLowerCase() ? (
						<mark style={{ background: '#FCFC83' }} key={part}>
							{part}
						</mark>
					) : (
						part
					)
				)}
			</span>
		</Tooltip>
	);
};
