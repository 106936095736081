import { Col, Row } from 'antd';
import { companyType } from '../D2r';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultFilter, defaultPageConfig, IFilter, topFilterUtil } from './utils';
import { IPageConfiguration } from 'app/AppAdmin/commonTypes';
import { CustomTablePagination } from '../../../../shared/components/CustomTablePagination';
import errorHandler from '../../../../shared/utils/errorHandler';
import { apiBms } from '../../../../shared/api';
import dayjs from 'dayjs';
import { getCreditNotes } from '../../../../shared/api/inventory';
import d2rCreditNoteColumns from './columns';

export const CreditNoteList = ({ company }: { company: companyType }) => {
	const [data, setData] = useState<any[]>([]);
	const [currentCount, setCurrentCount] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<IFilter>(defaultFilter);
	const [pageConfig, setPageConfig] = useState<IPageConfiguration>(defaultPageConfig);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [isVoucherXmlLoading, setIsVoucherXmlLoading] = useState(false);
	const [isVoucherExcelLoading, setIsVoucherExcelLoading] = useState(false);
	const columns = d2rCreditNoteColumns();

	const setPageConfigDefault = useCallback(() => {
		setPageConfig(defaultPageConfig);
	}, []);

	function onCSVDownload() {
		getCreditNotes(pageConfig, filter, 1);
	}

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				selectedRowKeys,
				onCSVDownload,
				onDownloadCreditVoucherXml,
				onDownloadCreditVoucherExcel,
				isVoucherXmlLoading,
				isVoucherExcelLoading
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filter, setFilter, setPageConfigDefault, selectedRowKeys, isVoucherXmlLoading, isVoucherExcelLoading]
	);

	async function onDownloadCreditVoucherXml() {
		try {
			if (selectedRowKeys.length === 0) {
				throw new Error('No order is selected.');
			}
			setIsVoucherXmlLoading(true);
			const { data } = await apiBms.post('/tally-voucher/download/credit-note/:xml', { orderIds: selectedRowKeys });

			if (data.success) {
				const blob = new Blob([data.xml], { type: 'text/xml' });
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.download = `CreditNoteVoucher${dayjs().format()}`;
				link.href = url;
				link.click();
				setIsVoucherXmlLoading(false);
			} else {
				throw new Error("Voucher for some orders can't be created. Download excel to know the reason.");
			}
		} catch (err) {
			setIsVoucherXmlLoading(false);
			errorHandler(err);
		}
	}
	async function onDownloadCreditVoucherExcel() {
		try {
			if (selectedRowKeys.length === 0) {
				throw new Error('No order is selected.');
			}

			setIsVoucherExcelLoading(true);

			const { data } = await apiBms.post(
				'/tally-voucher/download/credit-note/:excel',
				{ orderIds: selectedRowKeys },
				{ responseType: 'blob' }
			);

			const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
			let a = document.createElement('a');
			a.href = URL.createObjectURL(blob);
			a.download = `CreditNoteVoucher${dayjs().format()}`;
			a.click();
			setIsVoucherExcelLoading(false);
		} catch (err) {
			setIsVoucherExcelLoading(false);
			errorHandler(err);
		}
	}

	async function getAllCreditNotes(pageConfiguration: any, filters: any) {
		try {
			setLoading(true);
			const response = await getCreditNotes(pageConfiguration, filters);
			if (response?.data.status) {
				setData(response.data.creditNotes);
				setCurrentCount(response.data.totalRecords);
			} else {
				throw new Error(response?.data.message || 'Could not get payments');
			}
		} catch (error: unknown) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	console.log(selectedRowKeys);

	useEffect(() => {
		setFilter((prevFilter: IFilter) => ({ ...prevFilter, company }));
	}, [company]);

	useEffect(() => {
		getAllCreditNotes(pageConfig, filter);
	}, [filter, pageConfig]);

	return (
		<Row className="h-100 p-0">
			<Col span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={data}
							onChangePage={(current: any, pageSize: any) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							current={Number(pageConfig.current)}
							total={currentCount}
							loading={loading}
							shouldRowSelection={true}
							selectedItem={undefined}
							catalog={undefined}
							selectedRowKeys={undefined}
							setSelectedRowKeys={setSelectedRowKeys}
							setSelectedItem={undefined}
							pageSize={Number(pageConfig.pageSize)}
							sortField={pageConfig.sortField}
							sortOrder={pageConfig.sortField}
						/>
					</div>
				</div>
			</Col>
		</Row>
	);
};
