import { Button } from 'antd';

export const PrintCreditDebitNote = ({ orderDetails, type, disabled }: any) => {
	const InvoiceUrl = {
		Credit: orderDetails?.creditDebitNote?.creditNoteUrl
	};

	return (
		<Button
			type="dashed"
			size="small"
			target="_blank"
			style={{ width: '100%' }}
			rel="noreferrer"
			href={`https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/${InvoiceUrl[type as keyof typeof InvoiceUrl]}`}
			disabled={disabled}
		>
			{type} Note
		</Button>
	);
};
