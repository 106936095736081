import { Button, Tag } from 'antd';

export default function d2rCreditNoteColumns() {
	return [
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			render: (createdAt: string) => <div className="text-center">{new Date(createdAt).toLocaleDateString()}</div>,
			width: 100
		},
		{
			title: 'CreditNoteId',
			render: (data: any) => (
				<Button
					type="link"
					onClick={() => {
						window.open(`https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/${data?.creditNoteUrl}`, '_blank');
					}}
				>
					{data?.creditNumber}
				</Button>
			),
			width: 150,
			sorter: true
		},
		{
			title: 'Store name',
			dataIndex: 'splitOrder',
			render: (splitOrder: any) => (
				<div className="text-center">
					{splitOrder?.shippingD2RAddress
						? `${splitOrder?.shippingD2RAddress?.firstName + ' ' + splitOrder?.shippingD2RAddress?.lastName}`
						: '-'}
				</div>
			)
		},
		{
			title: 'Salesman',
			dataIndex: 'splitOrder',
			render: (splitOrder: any) => (
				<div className="text-center">
					{splitOrder?.salesman ? `${splitOrder?.salesman?.firstName + ' ' + splitOrder?.salesman?.lastName} ` : '-'}
				</div>
			),
			width: 150
		},
		{
			title: 'CN Amount',
			dataIndex: 'creditNoteFinalValue',
			render: (creditNoteFinalValue: string) => (
				<div className="text-center">
					<Tag color="red">{`₹` + Number(creditNoteFinalValue).toFixed(2)}</Tag>
				</div>
			),
			width: 150
		},
		{
			title: 'Invoice Id',
			dataIndex: 'splitOrder',
			render: (splitOrder: any) => (
				<Button
					type="link"
					onClick={() => {
						window.open(`https://wherehouse-prod-content.s3.ap-south-1.amazonaws.com/${splitOrder?.invoiceUrl}`, '_blank');
					}}
				>
					{splitOrder?.invoiceId ? splitOrder?.invoiceId : '-'}
				</Button>
			)
		}
	];
}
