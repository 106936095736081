import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, Space, Tooltip } from 'antd';
import styles from './index.module.scss';
import { StoreAddress } from './StoreAddress';
import TextArea from 'antd/lib/input/TextArea';
import { SalesmanDetails } from './salesmanDetails';
import { IManualOrderFormName, IManualOrderTypes } from '../../utils';
import { useContext, useEffect } from 'react';
import { AuthContext } from 'shared/contexts/Auth';
import { IManualOrderFields, IPriceInputType } from 'utils/typescript/types/orders';
// import { D2RWarehouse } from './d2rWarehouse';

export const EItemPaymentType = {
	NONE: 0,
	MARGIN: 1,
	DISCOUNT: 2,
	FLAT_PRICE: 3
};

const IPriceInputPlaceholder: IPriceInputType = {
	[EItemPaymentType.NONE]: { label: 'Price per quantity (₹)', value: 'price' },
	[EItemPaymentType.MARGIN]: { label: 'Enter margin (%)', value: 'itemPricing' },
	[EItemPaymentType.DISCOUNT]: { label: 'Enter Discount (%)', value: 'itemPricing' },
	[EItemPaymentType.FLAT_PRICE]: { label: 'Enter Flat Price (₹ Inc GST)', value: 'itemPricing' }
};

export const OrderDetails: React.FC<IManualOrderFields> = ({
	customStyle,
	catalogData,
	setD2ROrderFlagInParent,
	isShowTermOfPayment,
	isNewStoreAddress,
	setIsNewStoreAddress,
	addNewStoreAddress,
	editStoreDetailsSelection,
	storeAddressData,
	editStoreAddress,
	onSubmitSalesmanForm,
	setShouldShowSalesmanForm,
	shouldShowSalesmanForm,
	itemPaymentType,
	setItemPaymentType,
	setPricingType,
	setPricingValue,
	selectedItemDetails,
	setSelectedItemDetails,
	existOrderData,
	warehouses,
	isD2ROrder,
	setStoreAddressData
}) => {
	const {
		authState: { profile }
	} = useContext(AuthContext);

	const initialOrderTypeValue = () => {
		let orderType;

		if (profile.isD2RAccount === '1') {
			/**
			 * Possible cases:
			 * 1. edit & clone order
			 * 2. new order
			 */
			if (existOrderData) {
				// d2rOrder, b2bOrder, none
				let { isD2ROrder, isb2bOrder } = existOrderData;
				if (isD2ROrder) {
					orderType = 2;
				} else if (isb2bOrder) {
					orderType = 1;
				} else {
					orderType = 0;
				}
			} else {
				// new order is created-> if d2raccount-> create d2r order by default
				orderType = 2;
			}
			//not a d2r account
		} else if (existOrderData) {
			// d2rOrder, b2bOrder, none
			let { isD2ROrder, isb2bOrder } = existOrderData;
			if (isD2ROrder) {
				orderType = 2;
			} else if (isb2bOrder) {
				orderType = 1;
			} else {
				orderType = 0;
			}
		} else {
			// new order is created -->if account is not d2r account-> create default order (none)
			orderType = 0;
		}

		return orderType;
	};

	const handleOptionSelect = (id: string, key: string, value: any) => {
		setSelectedItemDetails((prevState: any) => ({
			...prevState,
			[id]: {
				...prevState[id],
				[key]: value
			}
		}));
	};

	const handleRemoveItem = (key: string) => {
		const deepClonedState = JSON.parse(JSON.stringify(selectedItemDetails));
		delete deepClonedState[key];
		setSelectedItemDetails(deepClonedState);
	};

	useEffect(() => {
		let orderType = initialOrderTypeValue();

		if (orderType === IManualOrderTypes.D2R_ORDER) {
			setD2ROrderFlagInParent({ target: { value: IManualOrderTypes.D2R_ORDER } });
		}
	}, []);

	return (
		<div className={`${styles.orderDetails} ${customStyle}`}>
			<h4 className={styles.heading}>Order Details</h4>
			<Row>
				<Form.Item name="orderType" initialValue={initialOrderTypeValue()}>
					<Radio.Group defaultValue={0} style={{ marginBottom: 20 }} onChange={setD2ROrderFlagInParent}>
						<Radio value={0}>None</Radio>
						<Radio value={1}>B2B Order</Radio>
						<Radio value={2}>D2R Order</Radio>
					</Radio.Group>
				</Form.Item>
			</Row>
			<Row>
				<Form.Item name="beatId" label="Beat Id" initialValue={existOrderData?.beatId}>
					<Input placeholder="Optional" />
				</Form.Item>
			</Row>
			<Row>
				<Col>
					<Form.Item name="id" label="Order ID" rules={[{ required: true, message: 'Please input Order ID!' }]}>
						<Input placeholder="WY-1012" maxLength={40} />
					</Form.Item>
				</Col>
				<Col style={{ marginLeft: '1rem' }}>
					<Form.Item name="purchaseOrderNumber" label="Purchase Order Number" initialValue={existOrderData?.purchaseOrderNumber}>
						<Input placeholder="Optional" maxLength={40} />
					</Form.Item>
				</Col>
			</Row>
			{isD2ROrder && (
				<Form.Item
					name={IManualOrderFormName.D2R_WAREHOUSE_ID}
					label="Warehouses"
					rules={[{ required: true, message: 'Please select warehouse' }]}
					initialValue={existOrderData?.warehouseId}
				>
					<Select style={{ width: '400px' }} placeholder="Select Warehouse" showSearch optionFilterProp="children">
						{warehouses.map(({ profile, active }) => {
							return (
								profile &&
								active && (
									<Select.Option value={profile.id} key={profile.id}>
										{`${profile.name}, ${profile.pincode}, ${profile.addressLine1}`}
									</Select.Option>
								)
							);
						})}
					</Select>
				</Form.Item>
			)}
			{/* <D2RWarehouse
				showStoreAddress={showStoreAddress}
				defaultWarehouseId={existOrderData?.warehouseId}
				warehouses={warehouses}
				editD2RWarehouseSelection={editD2RWarehouseSelection}
			/> */}
			<StoreAddress
				{...{
					isNewStoreAddress,
					setIsNewStoreAddress,
					addNewStoreAddress,
					editStoreDetailsSelection,
					storeAddressData,
					editStoreAddress,
					showStoreAddress: isD2ROrder,
					existOrderData,
					setStoreAddressData
				}}
			/>
			{!isNewStoreAddress && isShowTermOfPayment && (
				<Row gutter={12}>
					<Col span={12}>
						<Form.Item name="termsOfPayment" label="Terms Of Payment" initialValue={existOrderData?.termsOfPayment}>
							<TextArea placeholder="Optional" cols={20} />
						</Form.Item>
					</Col>
				</Row>
			)}
			{isShowTermOfPayment && !isNewStoreAddress && (
				<>
					<SalesmanDetails {...{ onSubmitSalesmanForm, setShouldShowSalesmanForm, shouldShowSalesmanForm, existOrderData }} />
					<Row style={{ marginTop: 8 }}>
						<Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
							<Form.Item name={'pricingType'} label="Items Pricing Terms (optional)">
								<Radio.Group
									defaultValue={0}
									style={{ marginBottom: 20 }}
									onChange={(event) => {
										setPricingType(event.target.value);
										setItemPaymentType(event.target.value);
									}}
									name="commonPrice"
								>
									<Radio value={EItemPaymentType.NONE}>None</Radio>
									<Radio value={EItemPaymentType.MARGIN}>Margin (%)</Radio>
									<Radio value={EItemPaymentType.DISCOUNT}>Discount (%)</Radio>
									<Radio value={EItemPaymentType.FLAT_PRICE}>Flat Price (₹ Inc GST)</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						{Boolean(itemPaymentType) &&
							itemPaymentType !== EItemPaymentType.FLAT_PRICE && ( // 3 => to avoid flat price condition
								<Col span={10}>
									<Form.Item
										name={'commonPricingValue'}
										label={IPriceInputPlaceholder[itemPaymentType]?.label}
										rules={[{ min: 0, max: 100 }]}
									>
										<Input
											type="number"
											name="commonPriceValue"
											style={{ width: '130px', marginTop: '-1rem' }}
											placeholder={`${IPriceInputPlaceholder[itemPaymentType]?.label}`}
											onChange={(e) => setPricingValue(e.target.value)}
										/>
									</Form.Item>
								</Col>
							)}
					</Row>
				</>
			)}
			<Form.List
				name="items"
				initialValue={
					existOrderData?.items
						? existOrderData?.items.map((dataItem: any) => ({
								...dataItem,
								id: dataItem.catalogueId,
								price: Math.round(dataItem.finalPrice / dataItem.quantity),
								isFree: Math.round(dataItem.finalPrice / dataItem.quantity) === 0
						  }))
						: []
				}
			>
				{(fields: any[], { add, remove }) => (
					<>
						{fields.map(({ key, name, fieldKey, ...restField }) => (
							<Space key={key} style={{ display: 'flex', marginBottom: 4, columnGap: '3rem' }} align="center">
								<Form.Item
									{...restField}
									name={[name, 'id']}
									label="Product"
									rules={[{ required: true, message: 'Please select product' }]}
								>
									<Select
										onSelect={(catalogueId: any) => {
											handleOptionSelect(key, 'catalogueId', catalogueId);
										}}
										style={{ width: '400px' }}
										placeholder="Select product"
										showSearch
										optionFilterProp="children"
									>
										{catalogData.map(({ id, productTitle, sku }) => {
											return <Select.Option key={id} value={id}>{`${productTitle} (${sku})`}</Select.Option>;
										})}
									</Select>
								</Form.Item>
								<Form.Item
									{...restField}
									name={[name, 'quantity']}
									label="Quantity"
									rules={[{ required: true, message: 'Please enter quantity' }]}
								>
									<InputNumber onChange={(e) => handleOptionSelect(key, 'quantity', e)} placeholder="3" min={1} />
								</Form.Item>
								<Form.Item {...restField} name={[name, 'isFree']} label="is Free?" rules={[{ required: false }]}>
									<Select
										style={{ width: '80px' }}
										defaultValue={false}
										options={[
											{
												value: true,
												label: 'True'
											},
											{
												value: false,
												label: 'False'
											}
										]}
										onChange={(e) => handleOptionSelect(key, 'isFree', e)}
									></Select>
								</Form.Item>
								<Tooltip title="Default catalog price will be set in case value is not provided">
									{((isShowTermOfPayment && Boolean(itemPaymentType)) || !isShowTermOfPayment) && (
										<Form.Item
											{...restField}
											name={[name, IPriceInputPlaceholder[itemPaymentType]?.value]}
											label={IPriceInputPlaceholder[itemPaymentType]?.label}
											initialValue={undefined}
											rules={[
												{
													required: [EItemPaymentType.DISCOUNT, EItemPaymentType.FLAT_PRICE].includes(
														itemPaymentType
													),
													message: 'This field is required'
												}
											]}
										>
											<InputNumber
												onChange={(e) => handleOptionSelect(key, 'pricingValue', e)}
												placeholder="Enter here"
											/>
										</Form.Item>
									)}
								</Tooltip>
								<Button
									danger
									onClick={() => {
										handleRemoveItem(key);
										remove(name);
									}}
									block
									icon={<DeleteOutlined />}
								>
									Delete
								</Button>
							</Space>
						))}
						{!isNewStoreAddress && (
							<Form.Item>
								<Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: '200px' }}>
									Add Product
								</Button>
							</Form.Item>
						)}
					</>
				)}
			</Form.List>
		</div>
	);
};
